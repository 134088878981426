import React, {useContext} from 'react';

import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import styles from "../cssModules/General.module.css";
import {Product} from "../components/Product";
import {GeneralContext} from "../context/general.context";

export const Products = () => {

  const generalContext = useContext(GeneralContext);

  return(
    <div>
      <Header/>
      <section className={styles.productsTableSection}>
        <div className={styles.wrapper}>
          <h1 className={styles.mainTitle}>Наши товары</h1>
          <div className={styles.productsTableContentContainer}>
            {
              generalContext.products &&
              generalContext.products.map(({name, description, price, photo}) => {
                return(
                    <Product id={name} name={name} description={description} price={price} photo={photo} key={name}/>
                );
              })
            }
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
}
