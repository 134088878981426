import styles from "../cssModules/General.module.css";

export const Footer = () => {
  return (
    <footer>
      <div className={styles.wrapper}>
        <div className={styles.footerContentContainer}>
          <div className={styles.footerContacts}>
            <h1 className={styles.mainTitle}>Контакты</h1>
            <p className={styles.footerDescriptionValue}>
              <a href='tel:+79281289363' className={styles.footerDescriptionValueLink}>+7 (928) 128-93-63</a>
            </p>
            <p className={styles.footerDescriptionValue}>
              <a href='mailto:tsv57@mail.ru' className={styles.footerDescriptionValueLink}>tsv57@mail.ru</a>
            </p>
          </div>
          <div className={styles.footerSchedule}>
            <h1 className={styles.mainTitle}>График</h1>
            <p className={styles.footerDescriptionValue}>Понедельник - Пятница</p>
            <p className={styles.footerDescriptionValue}>с 8:00 до 17:00</p>
          </div>
          <div className={styles.footerAddress}>
            <h1 className={styles.mainTitle}>Адрес</h1>
            <p className={styles.footerDescriptionValue}>Ростов-на-Дону</p>
            <p className={styles.footerDescriptionValue}>ул. Механизаторов 13.</p>
          </div>
        </div>
      </div>
    </footer>
  )
}
