import {useCallback, useEffect, useState} from "react";

import { BrowserRouter } from "react-router-dom";
import { useRoutes } from "./routes";

import { GeneralContext } from "./context/general.context";
import axios from "axios";

function App() {

  const routes = useRoutes();

  const [products, setProducts] = useState(null);

  const getResponses = useCallback(async () => {
    try{

      const response = await axios.get(`https://tolpygo-plastmass.ru/api/products/list`);

      setProducts(response.data.productList);

    }catch (e){}
  },[]);

  useEffect( () => {
    try {

      (async function fetchData() {
        await getResponses()
      }());

    } catch (e){}
  }, [getResponses]);

  return (
    <GeneralContext.Provider value={ { products } } >
      <BrowserRouter>
        { routes }
      </BrowserRouter>
    </GeneralContext.Provider>

  );
}

export default App;
