import styles from "../cssModules/General.module.css";

export const Popup = ({ success, visible }) => {

  return (
    <div className={`${styles.popup} ${success ? styles.popupSuccess : styles.popupError} ${visible ? styles.popupVisible : ''}`}>
      {
        success ? 'Заявка успешно отправлена' : 'Некорректные данные'
      }
    </div>
  )
}
