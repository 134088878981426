import React, {useState, useContext} from 'react';

import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Map, Placemark, YMaps } from "react-yandex-maps";

import clsx from "clsx";

import { Header } from '../components/Header';
import {Footer} from "../components/Footer";
import styles from '../cssModules/General.module.css';
import {GeneralContext} from "../context/general.context";

import mainKombain from '../images/main_kombain.png';
import secondKombine from '../images/second_kombain.png';
import agrosnab from '../images/agrosnab.png';
import rsm from '../images/rsm.png';
import bizon from '../images/bizon.png';
import agroprom from '../images/agroprom.png'
import {Popup} from "../components/Popup";
import axios from "axios";

export const Main = () => {

  const generalContext = useContext(GeneralContext);

  const [contactForm, setContactForm] = useState({
    name: '',
    phone: '',
    email: '',
    description: '',
  });

  const changeInputHandler = (event) => {

    setContactForm({...contactForm, [event.target.name]: event.target.value.trim()})
  };

  const [nameCorrect, setNameCorrect] = useState(true);
  const [phoneCorrect, setPhoneCorrect] = useState(true);
  const [emailCorrect, setEmailCorrect] = useState(true);
  const [descriptionCorrect, setDescriptionCorrect] = useState(true);

  const inputName = clsx({
    [styles.nameIncorrect] : !nameCorrect,
  });
  const inputPhone = clsx({
    [styles.phoneIncorrect] : !phoneCorrect,
  });
  const inputEmail = clsx({
    [styles.emailIncorrect] : !emailCorrect,
  });
  const inputDescription = clsx({
    [styles.descriptionIncorrect] : !descriptionCorrect,
  });

  const [formSuccess, setFormSuccess] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);

  const contactFormHandler = async () => {

    const isNameCorrect = contactForm.name.length;
    const isPhoneCorrect = contactForm.phone.length && Number(contactForm.phone);

    if (!isNameCorrect) {
      setNameCorrect(false);
    }
    if (!isPhoneCorrect) {
      setPhoneCorrect(false);
    }

    if (!isNameCorrect || !isPhoneCorrect) {
      setFormSuccess(false);
      setPopupVisible(true);
      closePopup();
    } else {
      await axios.post(`https://tolpygo-plastmass.ru/api/contact/sendMail`, { form: contactForm } )
        .then(response => {
          setContactForm({
            name: '',
            phone: '',
            email: '',
            description: '',
          })
          setFormSuccess(true);
          setPopupVisible(true);
        })
        .catch(error => {
          setFormSuccess(false);
          setPopupVisible(true);
        });
      closePopup();
    }
  };

  const closePopup = () => {
    setTimeout(() => {
      setFormSuccess(false);
      setPopupVisible(false);
    }, 2500)
  }

  return(
    <div>
      <Popup success={formSuccess} visible={popupVisible}/>
      <Header/>
      <section className={styles.mainSection}>
        <div className={styles.wrapper}>
          <div className={styles.mainSectionContentContainer}>
            <div className={styles.mainSectionDescriptionContainer}>
              <div className={styles.mainSectionTextContainer}>
                <h1 className={styles.mainTitle}>КОМПАНИЯ ИП «ТОЛПЫГО»</h1>
                <p className={styles.descriptionText}>
                  Производственная компания, занимающаяся подготовкой производства, и производством деталей из пластмасс и резино-технических изделий технического назначения.
                </p>
              </div>
              <a href={'#formSection'}>
                <div className={styles.productsSectionButtonContainer}>
                  <button className={styles.frameButton}>
                    Связаться
                  </button>
                </div>
              </a>
            </div>
            <img src={mainKombain} className={styles.mainSectionKombinePicture} alt={'mainSectionKombinePicture'}/>
          </div>
        </div>
      </section>
      <section className={styles.productsSection}>
        <div className={styles.wrapper}>
          <h1 className={styles.mainTitle}>Товары</h1>
        </div>
        <div className={styles.productsSectionBackgroundContainer}>
          <div className={styles.wrapper}>
            <div className={styles.productsSectionContentContainer}>
              <Swiper
                slidesPerView={4}
                slidesPerGroup={2}
                loop={true}
                pagination={false}
                navigation={true}
                breakpoints={{
                  0: {
                    slidesPerView: 2,
                    slidesPerGroup: 1,
                    spaceBetween: 10
                  },
                  480: {
                    slidesPerView: 3,
                    spaceBetween: 10
                  },
                  640: {
                    slidesPerView: 4,
                    spaceBetween: 10
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 20
                  }
                }}
                modules={[Pagination, Navigation]}
              >
                {
                  generalContext.products &&
                  generalContext.products.map(({name, photo}) => {
                    return(
                      <SwiperSlide className={styles.productsSectionProductContentContainer} key={name}>
                        <img src={`https://tolpygo-plastmass.ru/api/products/photo/${photo}`} className={styles.productsSectionProductPicture} alt='img'/>
                        <div className={styles.productsSectionItemNameBlock}>
                          <p className={styles.productsSectionItemName}>{name}</p>
                        </div>
                      </SwiperSlide>
                    );
                  })
                }
              </Swiper>
              <a href={'/products'}>
                <button type={'button'}  className={styles.frameButton}>Показать все</button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.partnersSection}>
        <div className={styles.wrapper}>
          <h1 className={styles.mainTitle}>Партнёры</h1>
          <div className={styles.partnersSectionContentContainer}>
            <div className={styles.partnersSectionCardItem}>
              <img src={rsm}className={styles.partnersSectionLogoPicture} alt={'partnersSectionLogoPicture'}/>
            </div>
            <div className={styles.partnersSectionCardItem}>
              <img src={agrosnab} className={styles.partnersSectionLogoPicture} alt={'partnersSectionLogoPicture'}/>
            </div>
            <div className={styles.partnersSectionCardItem}>
              <img src={bizon} className={styles.partnersSectionLogoPicture} alt={'partnersSectionLogoPicture'}/>
            </div>
            <div className={styles.partnersSectionCardItem}>
              <img src={agroprom} className={styles.partnersSectionLogoPicture} alt={'partnersSectionLogoPicture'}/>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.descriptionSection}>
        <div className={styles.wrapper}>
          <div className={styles.descriptionSectionContentContainer}>
            <img src={secondKombine} className={styles.descriptionSectionKombinePicture} alt={'descriptionSectionKombinePicture'}/>
            <div className={styles.descriptionSectionTextContainer}>
              <h1 className={styles.mainTitle}>О нас</h1>
              <p className={styles.descriptionText}>
                Наша компания производит заглушки, планки, прокладки, втулки, глазки, полуподшипники и скребки для зерноуборочной техники.
              </p>
              <p className={styles.descriptionText}>
                На нашем предприятии, Вы также сможете разместить производство изделий на Вашей оснастке. Материалы, которые мы используем в производстве: полиамид, АБС, полистирол, ПЭВД, ПЭНД, полипропилен, термоэластопласты, ПВХ и т. д.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.formSection} id="formSection">
        <div className={styles.wrapper}>
          <h1 className={styles.mainTitle}>Связаться с нами</h1>
          <div className={styles.formSectionContentContainer}>
            <form className={styles.formSectionContactForm}>
              <input className={`${styles.formSectionInput} ${inputName}`} value={contactForm.name} placeholder={'Ваше имя'} name={'name'} onChange={changeInputHandler} onClick={() => setNameCorrect(true)} />
              <input className={`${styles.formSectionInput} ${inputPhone}`} value={contactForm.phone} placeholder={'Номер телефона'} name={'phone'} onChange={changeInputHandler} onClick={() => setPhoneCorrect(true)} />
              <input className={`${styles.formSectionInput} ${inputEmail}`} value={contactForm.email} placeholder={'Email'} name={'email'} onChange={changeInputHandler} onClick={() => setEmailCorrect(true)}/>
              <textarea className={`${styles.formSectionInput} ${inputDescription}`} value={contactForm.description} placeholder={'Описание товара или услуги'} name={'description'} onChange={changeInputHandler} onClick={() => setDescriptionCorrect(true)}/>
              <div className={styles.formSectionCustomerAgreement}>
                Нажимая кнопку «Отправить», вы соглашаетесь с
                <a className={styles.formSectionCustomerAgreementLink} href={'/'}> Политикой конфиденциальности</a>
                .
              </div>
              <button type={'button'} className={styles.filledButton} onClick={contactFormHandler}>
                Отправить
              </button>
            </form>
            <div className={styles.formSectionDescriptionBlock}>
              <YMaps style={{ minWidth: "100%", minHeight: "100%" }}>
                <Map
                  state={{
                    center: [47.271015, 39.775263],
                    zoom: 16,
                    behaviors: ["default", "scrollZoom"]
                  }}
                  width="100%"
                  height="100%"
                >
                  <Placemark
                    geometry={[47.271015, 39.775263]}
                  />
                </Map>
              </YMaps>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
}
