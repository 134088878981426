import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';
import { Main, Products } from "./pages";

export const useRoutes = () => {
  return(
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/products" element={<Products />} />
      <Route
        path="*"
        element={<Navigate to="/" replace />}
      />
    </Routes>
  );
};
