import {HashLink as Link} from "react-router-hash-link";

import styles from "../cssModules/General.module.css";
import logo from "../images/logo.png";

export const Header = () => {
  return (
    <header>
      <a className={styles.headerLogo} href={'/'}>
        <img src={logo} className={styles.headerLogoPicture} alt={'logo'}/>
      </a>
      <div className={styles.headerContactBlock}>
        <div className={styles.headerPhone}>
          <a href='tel:+79281289363'>+7 (928) 128-93-63</a>
        </div>
        <Link to={'/#formSection'}>
          <button className={styles.filledButton}>
            Написать нам
          </button>
        </Link>
      </div>
    </header>
  )
}
