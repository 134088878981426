import styles from "../cssModules/General.module.css";
import {HashLink as Link} from "react-router-hash-link";

export const Product = ({name, description, price, photo}) => {
  return (
    <div className={styles.productsTableContentBlock}>
      <img src={`https://tolpygo-plastmass.ru/api/products/photo/${photo}`} className={styles.productsTableContentBlockImage} alt='img'/>
      <div className={styles.productsTableContentItemText}>
        <div className={styles.productsTableContentItemName}>
          {name}
        </div>
        <div className={styles.productsTableContentItemDescription}>
          {description}
        </div>
        <div className={styles.productsTableContentItemPrice}>
          {price || '–'} р/шт.
        </div>
      </div>
      <Link to={'/#formSection'}>
        <button className={styles.filledButton}>
          Сделать заказ
        </button>
      </Link>
    </div>
  )
}
